import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';

// 1) Thunk to check if user has valid credentials (Google Ads OAuth)
export const checkGoogleAdsAuthorization = createAsyncThunk(
  'googleAdsAccounts/checkGoogleAdsAuthorization',
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.get('/api/google-ads/check_authorization/');
      const { missing_credentials, redirect } = response.data || {};
      if (missing_credentials || redirect) {
        // Redirect the user to connect if missing credentials
        window.location.href = redirect || '/connect-google-ads-account';
        return { isRedirected: true };
      }
      return response.data; // e.g. { message: 'Authorized successfully.' }
    } catch (error) {
      console.error('Error checking Google Ads authorization:', error);
      const errorMessage = error?.response?.data?.error || 'Failed to check authorization';
      return rejectWithValue(errorMessage);
    }
  }
);

// 2) Thunk to fetch new (unconnected) sub-accounts from Google Ads API
export const fetchGoogleAdsAccounts = createAsyncThunk(
  'googleAdsAccounts/fetchGoogleAdsAccounts',
  async (_, { rejectWithValue }) => {
    try {
      // GET /api/google-ads/accounts/
      // returns { account_list, user_tier, tier_limit }
      const response = await backendAxiosInstance.get('/api/google-ads/accounts/');
      const { account_list = [], user_tier = 'Basic', tier_limit = 1 } = response.data || {};
      return { account_list, user_tier, tier_limit };
    } catch (error) {
      console.error('Error fetching Google Ads accounts:', error);
      const fallback = error?.response?.data?.error || 'Failed to fetch accounts';
      return rejectWithValue(fallback);
    }
  }
);

// 3) Thunk to actually connect those selected accounts => store them in DB
export const connectGoogleAdsAccount = createAsyncThunk(
  'googleAdsAccounts/connectGoogleAdsAccount',
  async (selectedAccounts, { rejectWithValue }) => {
    try {
      // POST /api/google-ads/accounts/
      // { selected_accounts: [ {customer_id, descriptive_name}... ] }
      const response = await backendAxiosInstance.post('/api/google-ads/accounts/', {
        selected_accounts: selectedAccounts,
      });
      return response.data; // { message: 'Accounts connected successfully.' }
    } catch (error) {
      console.error('Error connecting Google Ads accounts:', error);
      const fallback = error?.response?.data?.error || 'Failed to connect accounts';
      return rejectWithValue(fallback);
    }
  }
);

// 4) Thunk to fetch the *already connected* accounts from DB
export const fetchConnectedGoogleAdsAccounts = createAsyncThunk(
  'googleAdsAccounts/fetchConnectedGoogleAdsAccounts',
  async (_, { rejectWithValue }) => {
    try {
      // GET /api/google-ads/select_account/
      // returns { connected_google_ads_accounts: [...] }
      const response = await backendAxiosInstance.get('/api/google-ads/select_account/');
      const { connected_google_ads_accounts = [] } = response.data || {};
      return connected_google_ads_accounts;
    } catch (error) {
      console.error('Error fetching connected Google Ads accounts:', error);
      const fallback = error?.response?.data?.error || 'Failed to fetch connected accounts';
      return rejectWithValue(fallback);
    }
  }
);

// Utility to load user’s “currently selected” accounts from localStorage
function loadSelectedAccountsFromStorage() {
  try {
    const rawData = localStorage.getItem('selectedGoogleAdsAccounts');
    return rawData ? JSON.parse(rawData) : [];
  } catch {
    return [];
  }
}

// The slice
const googleAdsAccountsSlice = createSlice({
  name: 'googleAdsAccounts',
  initialState: {
    // list of sub-accounts that user can connect (fetched from Google Ads API)
    accounts: [],
    // list of accounts that user already connected (fetched from DB)
    connectedAccounts: [],
    // whichever accounts user is “selecting” in the UI
    selectedAccounts: loadSelectedAccountsFromStorage(),
    // from the server’s responses
    tier: '',
    tierLimit: '',
    // some basic loading/error states
    loading: false,
    error: null,
    successMessage: null,
    // track if user is authorized for Google Ads
    isAuthorized: false,
  },
  reducers: {
    // toggles an account in/out of `selectedAccounts` (in local UI)
    toggleAccountSelection: (state, action) => {
      const newAcct = action.payload;
      const exists = state.selectedAccounts.some(
        (acct) => acct.account_id === newAcct.account_id
      );

      if (exists) {
        // remove it
        state.selectedAccounts = state.selectedAccounts.filter(
          (acct) => acct.account_id !== newAcct.account_id
        );
      } else {
        // add it
        // optionally enforce tier limit
        if (state.selectedAccounts.length >= state.tierLimit) {
          state.error = `You can only select up to ${state.tierLimit} accounts based on your plan.`;
          return;
        }
        state.selectedAccounts.push(newAcct);
      }

      // persist to localStorage
      localStorage.setItem(
        'selectedGoogleAdsAccounts',
        JSON.stringify(state.selectedAccounts)
      );

      // clear any stale error if the user overcame it
      state.error = null;
    },

    // utility to clear everything
    clearAccountsState: (state) => {
      state.accounts = [];
      state.connectedAccounts = [];
      state.selectedAccounts = [];
      state.loading = false;
      state.error = null;
      state.successMessage = null;
      state.isAuthorized = false;
      localStorage.removeItem('selectedGoogleAdsAccounts');
    },
  },
  extraReducers: (builder) => {
    builder
      // 1) checkGoogleAdsAuthorization
      .addCase(checkGoogleAdsAuthorization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkGoogleAdsAuthorization.fulfilled, (state, action) => {
        state.loading = false;
        // if isRedirected => do nothing
        if (action.payload?.isRedirected) return;
        // user is authorized
        state.isAuthorized = true;
      })
      .addCase(checkGoogleAdsAuthorization.rejected, (state, action) => {
        state.loading = false;
        state.isAuthorized = false;
        state.error = action.payload;
      })

      // 2) fetchGoogleAdsAccounts
      .addCase(fetchGoogleAdsAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGoogleAdsAccounts.fulfilled, (state, action) => {
        state.loading = false;
        const { account_list, user_tier, tier_limit } = action.payload;
        state.accounts = account_list;
        state.tier = user_tier;
        state.tierLimit = tier_limit;
      })
      .addCase(fetchGoogleAdsAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // 3) connectGoogleAdsAccount
      .addCase(connectGoogleAdsAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(connectGoogleAdsAccount.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = 'Accounts connected successfully!';
        // optionally clear local selection
        state.selectedAccounts = [];
        localStorage.removeItem('selectedGoogleAdsAccounts');
      })
      .addCase(connectGoogleAdsAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // 4) fetchConnectedGoogleAdsAccounts
      .addCase(fetchConnectedGoogleAdsAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConnectedGoogleAdsAccounts.fulfilled, (state, action) => {
        state.loading = false;
        // store them in .connectedAccounts
        state.connectedAccounts = action.payload;
      })
      .addCase(fetchConnectedGoogleAdsAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  toggleAccountSelection,
  clearAccountsState,
} = googleAdsAccountsSlice.actions;

export default googleAdsAccountsSlice.reducer;
