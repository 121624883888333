import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import SelectGoogleAdsAccount from '../../components/google_ads/SelectGoogleAdsAccount';

const SelectGoogleAdsAccountPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectionValid, setIsSelectionValid] = useState(false);

  useEffect(() => {
    const persisted = localStorage.getItem('selectedGoogleAdsAccounts'); // Match the key used in local storage
    setIsSelectionValid(!!persisted);
    console.log('Persisted selection:', persisted);
  }, []);

  const handleSelectionChange = (selectedAccount) => {
    if (selectedAccount && selectedAccount.account_id) { // Match the updated structure
      setIsSelectionValid(true);
      console.log('Selection is valid:', selectedAccount);
    } else {
      setIsSelectionValid(false);
      console.warn('Invalid Google Ads account selection.');
      alert('Please select a valid Google Ads account.');
    }
  };

  const handleContinue = () => {
    if (isSelectionValid) {
      setIsLoading(true);
      navigate('/google-ads-dashboard', { replace: true });
      setIsLoading(false);
    }
  };

  return (
    <div className="user-screen">
      <div className="content-users small">
        <h2>Select Google Ads Account for Analysis</h2>

        {/* Pass the selection change handler */}
        <SelectGoogleAdsAccount onSelectionChange={handleSelectionChange} />

        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={!isSelectionValid || isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Continue to Dashboard'}
        </Button>
      </div>
    </div>
  );
};

export default SelectGoogleAdsAccountPage;
