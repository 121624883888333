// GoogleAdsAccountsPage.js
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchGoogleAdsAccounts,
  connectGoogleAdsAccount,
  toggleAccountSelection,
} from '../../features/google_ads/googleAdsAccountsSlice';

import { DataGrid } from '@mui/x-data-grid';
import { Button, Checkbox, Skeleton, Box } from '@mui/material';

const GoogleAdsAccountsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    accounts = [],           // e.g. [ {customer_id, descriptive_name, manager_name, connected}, ... ]
    selectedAccounts = [],   // e.g. [ { customer_id, descriptive_name }, ... ]
    tier = '',
    tierLimit = '',
    loading,
    error,
    successMessage,
  } = useSelector((state) => state.googleAdsAccounts);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // 1) Fetch Google Ads accounts on mount
  useEffect(() => {
    dispatch(fetchGoogleAdsAccounts());
  }, [dispatch]);

  // 2) Build the rows for DataGrid
  const rows = useMemo(() => {
    console.log('Accounts from Redux:', accounts);

    // Each item in `accounts` might look like:
    // {
    //   customer_id: "customers/123",
    //   descriptive_name: "My Account",
    //   manager_name: "Parent Manager",
    //   connected: true/false
    // }

    return accounts.map((acc) => {
      // Remove "customers/" prefix from the ID, if present
      const rawId = acc.customer_id.replace(/^customers\//, '');

      return {
        // DataGrid needs a unique `id` field
        id: `${rawId}::${acc.manager_name || ''}`,
        rawCustomerId: rawId,
        accountName: acc.descriptive_name,
        managerName: acc.manager_name || '—',
        connected: acc.connected ? 'Connected' : 'Not Connected',
      };
    });
  }, [accounts]);

  // 3) Toggling selection
  const handleAccountSelection = (rawCustomerId, descriptiveName, isConnected) => {
    if (isConnected) {
      alert('This account is already connected and cannot be unselected.');
      return;
    }

    // Build the object we want to pass to `toggleAccountSelection`
    const selectedObj = {
      customer_id: rawCustomerId,
      descriptive_name: descriptiveName,
    };

    dispatch(toggleAccountSelection(selectedObj));
  };

  // 4) Submit selected accounts
  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirmMsg = 'Are you sure you want to connect the selected accounts?';
    if (!window.confirm(confirmMsg)) return;

    setIsSubmitting(true);
    try {
      // selectedAccounts is e.g. [ {customer_id: "123", descriptive_name: "Account #123"} ]
      await dispatch(connectGoogleAdsAccount(selectedAccounts)).unwrap();
      navigate('/select-google-ads-account'); // or wherever you want to go after connecting
    } catch (err) {
      console.error('Error submitting selected accounts:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  // 5) Skeleton placeholders while `loading`
  const renderSkeleton = () => (
    <Box sx={{ height: 400, width: '100%' }}>
      {Array.from({ length: 5 }).map((_, i) => (
        <Skeleton key={i} variant="rectangular" height={40} sx={{ mb: 1 }} />
      ))}
    </Box>
  );

  // 6) DataGrid columns
  const columns = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      width: 300,
    },
    {
      field: 'managerName',
      headerName: 'Manager Account',
      width: 200,
    },
    {
      field: 'connected',
      headerName: 'Connection Status',
      width: 200,
      renderCell: (params) => {
        const statusValue = params.value;
        const isConnected = statusValue === 'Connected';
        const style = { color: isConnected ? 'green' : 'red' };
        return <span style={style}>{statusValue}</span>;
      },
    },
    {
      field: 'select',
      headerName: 'Select Account',
      width: 200,
      renderCell: (params) => {
        const row = params.row;
        const isConnected = row.connected === 'Connected';
        const isChecked = selectedAccounts.some(
          (acct) => acct.customer_id === row.rawCustomerId
        );

        return (
          <Checkbox
            checked={isChecked}
            onChange={() =>
              handleAccountSelection(row.rawCustomerId, row.accountName, isConnected)
            }
            disabled={isConnected}
          />
        );
      },
    },
  ];

  // 7) Render
  return (
    <div className="user-screen">
      <div className="content-users big">
        <h2 className="text-center">Connect Your Google Ads Accounts</h2>

        {error && (
          <p className="error-message" role="alert" aria-live="polite">
            {error}
          </p>
        )}
        {successMessage && (
          <p className="success-message" role="alert" aria-live="polite">
            {successMessage}
          </p>
        )}

        <p>
          <strong>Note:</strong> You are in the {tier} plan. You can select up to{' '}
          {tierLimit} accounts.
        </p>
        <p>
          {selectedAccounts.length} out of {tierLimit} accounts selected.
        </p>

        <form onSubmit={handleSubmit}>
          <div style={{ height: 400, width: '100%' }}>
            {loading ? (
              renderSkeleton()
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                checkboxSelection={false} // We are handling checkboxes manually
                disableSelectionOnClick
              />
            )}
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={isSubmitting || selectedAccounts.length === 0}
          >
            {isSubmitting ? 'Submitting...' : 'Connect to So What Labs'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default GoogleAdsAccountsPage;
