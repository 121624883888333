import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectedGoogleAdsAccounts, toggleAccountSelection } from '../../features/google_ads/googleAdsAccountsSlice';
import { CircularProgress, MenuItem, Select, FormControl, InputLabel, Alert } from '@mui/material';

const SelectGoogleAdsAccount = ({ onSelectionChange }) => {
  const dispatch = useDispatch();
  const { connectedAccounts, selectedAccounts, loading, error } = useSelector((state) => state.googleAdsAccounts);

  const [selectedValue, setSelectedValue] = useState('');

  // Fetch the list of connected accounts from the database on mount
  useEffect(() => {
    if (connectedAccounts.length === 0 && !loading) {
      dispatch(fetchConnectedGoogleAdsAccounts()).catch((err) => {
        console.error('Failed to fetch connected Google Ads accounts:', err);
      });
    }
  }, [dispatch, connectedAccounts.length, loading]);

  // Update local state and notify parent when global selected accounts change
  useEffect(() => {
    if (selectedAccounts.length > 0) {
      const account = selectedAccounts[0]; // Use the first selected account
      setSelectedValue(JSON.stringify(account));
      onSelectionChange?.(account);
    } else {
      setSelectedValue('');
    }
  }, [selectedAccounts, onSelectionChange]);

  const handleSelectionChange = (event) => {
    const raw = event.target.value;
    setSelectedValue(raw);
    const chosenObj = JSON.parse(raw); // Parse JSON to get the account object
    dispatch(toggleAccountSelection(chosenObj));
    onSelectionChange?.(chosenObj); // Notify parent
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <p>Loading connected Google Ads accounts...</p>
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{typeof error === 'string' ? error : 'An unknown error occurred.'}</Alert>;
  }

  if (connectedAccounts.length === 0) {
    return <p>No connected Google Ads accounts found. Please connect an account to continue.</p>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="select-google-ads-account-label">Select Google Ads Account</InputLabel>
      <Select
        labelId="select-google-ads-account-label"
        value={selectedValue}
        onChange={handleSelectionChange}
        label="Select Google Ads Account"
        required
      >
        {connectedAccounts.map((acct) => {
          const valString = JSON.stringify({
            account_id: acct.account_id,
            account_name: acct.account_name,
          });
          return (
            <MenuItem key={acct.account_id} value={valString}>
              {acct.account_name || `Account (${acct.account_id})`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectGoogleAdsAccount;
