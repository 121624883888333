import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchDashboardData, addToReport } from "../../features/ga4/dashboardSlice";
import DateRangePicker from "../../components/DateRangePicker";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Skeleton, Grid, Button } from "@mui/material";
import { formatNumber } from "../../utils/numberUtils";
import { selectPropertyId } from "../../store/selectors";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Adjusted Redux state selectors
  const { widgets, commonStatus: status, commonError: error } = useSelector((state) => state.dashboard);
  const propertyId = useSelector(selectPropertyId);
  const dateRange = useSelector((state) => state.dateRange);

  // Local state to manage selected dates
  const [dates, setDates] = useState({
    startDate: dateRange.startDate || new Date(),
    endDate: dateRange.endDate || new Date(),
    prevStartDate: dateRange.prevStartDate,
    prevEndDate: dateRange.prevEndDate,
    isComparisonEnabled: dateRange.isComparisonEnabled,
  });

  // Fetch data function
  const fetchData = useCallback(async () => {
    if (!propertyId || !dates.startDate || !dates.endDate) {
      console.log("Property ID or dates not set. Skipping data fetch.");
      return;
    }

    try {
      await dispatch(
        fetchDashboardData({
          startDate: dates.startDate,
          endDate: dates.endDate,
          prevStartDate: dates.isComparisonEnabled ? dates.prevStartDate : null,
          prevEndDate: dates.isComparisonEnabled ? dates.prevEndDate : null,
          isComparisonEnabled: dates.isComparisonEnabled,
        })
      );
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  }, [dispatch, propertyId, dates]);

  // Trigger data fetching
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Redirect to `connect-ga4-account` if no property is selected
  if (!propertyId && status !== "loading") {
    navigate("/connect-ga4-account");
    return null;
  }

  // Handle date range changes
  const handleDateChange = ({ startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled }) => {
    setDates({
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      prevStartDate: isComparisonEnabled ? prevStartDate : null,
      prevEndDate: isComparisonEnabled ? prevEndDate : null,
      isComparisonEnabled: isComparisonEnabled,
    });
  };

  // Handle adding items to the report
  // const handleAddToReport = (metric) => {
  //   dispatch(addToReport(metric));
  //   alert(`${metric.label} added to the report.`);
  // };

    const [addedMetrics, setAddedMetrics] = useState({});
  
    const handleToggleReport = (metric) => {
      setAddedMetrics((prev) => ({
        ...prev,
        [metric.label]: !prev[metric.label], // Toggle state
      }));
    };
  // Render metrics as cards
  const renderMetrics = () => {
    const totalsData = widgets.totals.data;

    if (!totalsData || !totalsData.current) {
      console.warn("No valid dashboard data:", totalsData);
      return <div>No data available.</div>;
    }

    const currencySymbol = totalsData.currency_symbol || "";
    const metrics = [
      { label: "Active Users", key: "activeUsers", decimalPlaces: 0 },
      { label: "New Users", key: "newUsers", decimalPlaces: 0 },
      { label: "Sessions", key: "sessions", decimalPlaces: 0 },
      { label: "Pageviews", key: "screenPageViews", decimalPlaces: 0 },
      { label: "Avg. Session Duration", key: "averageSessionDuration", suffix: "s", decimalPlaces: 2 },
      { label: "Engagement Rate", key: "engagementRate", suffix: "%", multiplier: 100, decimalPlaces: 2 },
      { label: "Sessions/User", key: "sessionsPerUser", decimalPlaces: 2 },
      { label: "Pageviews/Session", key: "screenPageViewsPerSession", decimalPlaces: 2 },
      { label: "Conversions (All)", key: "conversions", decimalPlaces: 0 },
      { label: "Purchases", key: "ecommercePurchases", decimalPlaces: 0 },
      {
        label: "Purchaser Conv. Rate",
        key: "purchaserConversionRate",
        suffix: "%",
        multiplier: 100,
        decimalPlaces: 2,
      },
      {
        label: "Total Revenue",
        key: "totalRevenue",
        prefix: currencySymbol,
        decimalPlaces: 2,
      },
    ];

    return (
      <Grid container spacing={2}>
        {metrics.map((metric, index) => {
          const multiplier = metric.multiplier || 1;
          const decimalPlaces = metric.decimalPlaces || 0;
          const current = (totalsData.current[metric.key] || 0) * multiplier;
          const previous = (totalsData.previous?.[metric.key] || 0) * multiplier;

          const diff = previous !== 0 ? ((current - previous) / previous) * 100 : 0;
          const diffClass = diff > 0 ? "green-arrow" : diff < 0 ? "red-arrow" : "";

          const isAdded = addedMetrics[metric.label];

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <div className="card">
                <h3>{metric.label}</h3>
                <p>
                  {metric.prefix || ""}
                  {formatNumber(current, decimalPlaces)}
                  {metric.suffix || ""}
                </p>
                {dates.isComparisonEnabled && dates.prevStartDate && dates.prevEndDate && (
                  <small>
                    ({formatNumber(previous, decimalPlaces)}
                    {metric.suffix || ""})
                    {previous !== 0 && (
                      <span className={diffClass}>
                        {diff > 0 ? "↑" : diff < 0 ? "↓" : ""} {Math.abs(diff).toFixed(2)}%
                      </span>
                    )}
                  </small>
                )}

<Button
            variant="outlined"
            size="small"
            onClick={() => handleToggleReport(metric)}
            style={{ marginTop: "10px" }}
          >
            {isAdded ? "✔ Remove" : "+ Add to Report"}
          </Button>
          {isAdded && <div className="tick-animation">✔</div>}

              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // Render skeleton loaders while data is being fetched
  const renderSkeletons = () => {
    const skeletons = Array.from(new Array(12)).map((_, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <div className="card">
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="rectangular" height={60} />
        </div>
      </Grid>
    ));
    return (
      <Grid container spacing={2}>
        {skeletons}
      </Grid>
    );
  };

  return (
    <div className="container">
      <div className="main">
        <Sidebar />
        <Navbar />

        <div className="content">
          <div className="title-filter">
            <h1>Overview</h1>
            <div className="date-pickers-block">
              <DateRangePicker onDateChange={handleDateChange} />
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          {status === "loading" ? renderSkeletons() : renderMetrics()}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
