import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import ga4Reducer from '../features/ga4/ga4Slice';
import subscriptionReducer from '../features/subscription/subscriptionSlice';
import ga4ConnectReducer from '../features/ga4/ga4ConnectSlice';
import accountPropertyReducer from '../features/ga4/accountPropertySlice';
import dateReducer from '../features/date/dateSlice';
import dashboardReducer from '../features/ga4/dashboardSlice';
import audienceOverviewReducer from '../features/ga4/audienceOverviewSlice';
import audienceTotalReducer from '../features/ga4/audienceTotalSlice';
import audienceChartReducer from '../features/ga4/audienceChartSlice'; // Audience chart slice
import demographicsReducer from '../features/ga4/demographicsSlice'; // Import the demographics slice
import baseReducer from '../features/ga4/baseSlice';
import categoryInterestReducer from '../features/ga4/categoryInterestSlice'; // Add the categoryInterest reducer here
import campaignReducer from '../features/ga4/campaignSlice'; // Add the campaign reducer here

// Import the conversions reducers
import conversionsReducer from '../features/ga4/conversionsSlice';
import conversionsTotalReducer from '../features/ga4/conversionsTotalSlice';
import conversionsChartReducer from '../features/ga4/conversionsChartSlice';

// Import the googleAds reducer
import googleAdsReducer from '../features/ga4/googleAdsSlice';
import googleAdsAccountsReducer from '../features/google_ads/googleAdsAccountsSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    ga4: ga4Reducer,
    ga4Connect: ga4ConnectReducer,
    accountProperty: accountPropertyReducer,
    subscription: subscriptionReducer,
    dateRange: dateReducer,
    dashboard: dashboardReducer,
    audienceOverview: audienceOverviewReducer,
    audienceTotal: audienceTotalReducer,
    audienceChart: audienceChartReducer, // Audience chart reducer
    demographics: demographicsReducer, // Demographics reducer
    analytics: baseReducer,
    categoryInterest: categoryInterestReducer, // Add the categoryInterest reducer here
    campaign: campaignReducer, // Add the campaign reducer here

    // Conversions reducers
    conversions: conversionsReducer,
    conversionsTotal: conversionsTotalReducer,
    conversionsChart: conversionsChartReducer,

    // Google Ads reducer
    googleAds: googleAdsReducer,
    googleAdsAccounts: googleAdsAccountsReducer,

  },
});

export default store;
