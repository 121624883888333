// src/pages/ga4/CategoryInterestPage.js

import React from 'react';
import CategoryInterestComponent from '../../components/ga4/CategoryInterestComponent';

const CategoryInterestPage = () => {
  return (
    <CategoryInterestComponent
      moduleName="categoryinterest"
      moduleTitle="Category Interest Overview"
    />
  );
};

export default CategoryInterestPage;
