import axios from 'axios';
import { store } from '../store/store';
import { logout, refreshAccessToken } from '../features/auth/authSlice';

const backendAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Request interceptor: attach JWT token if available
backendAxiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth?.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor: handle 401 (refresh) & 403 (redirect) silently
backendAxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { response } = error;

    // ----- 1) Handle 401 Unauthorized: Attempt token refresh -----
    if (response && response.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Attempt to refresh the access token
          const refreshResult = await store.dispatch(refreshAccessToken());
          if (refreshResult.payload && refreshResult.payload.accessToken) {
            const newAccessToken = refreshResult.payload.accessToken;
            // Update store & request headers
            store.dispatch({ type: 'auth/setAccessToken', payload: newAccessToken });
            backendAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

            // Retry original request
            return backendAxiosInstance(originalRequest);
          } else {
            console.warn('Access token refresh failed. Redirecting to /login.');
            store.dispatch(logout());
            window.location.href = '/login';
            return Promise.reject(error);
          }
        } catch (refreshError) {
          console.error('Token refresh error:', refreshError);
          store.dispatch(logout());
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      }
    }

    // ----- 2) Handle 403 Forbidden: missing credentials or no permission -----
    if (response && response.status === 403) {
      // The backend might send something like:
      //   { redirect: "/connect-google-ads-account" } or missing_credentials: true
      const redirectPath = response.data?.redirect; // e.g. "/connect-ga4-account" or "/connect-google-ads-account"
      if (redirectPath) {
        // Silently redirect without storing or popping up an error
        window.location.href = redirectPath;
        // Return here so it doesn't bubble up as an error in Redux
        return Promise.reject(error);
      } else {
        // Otherwise fallback to logout & redirect to /login
        console.warn('403 Forbidden with no redirect path. Logging out and going to /login.');
        store.dispatch(logout());
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }

    // ----- 3) For all other errors, reject as normal. This can show up in Redux if needed. -----
    console.error('API call error:', error);
    return Promise.reject(error);
  }
);

export default backendAxiosInstance;
