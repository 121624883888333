import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGoogleAdsData, predictGoogleAds } from '../../features/ga4/googleAdsSlice';
import { selectPropertyId } from '../../store/selectors'; // Import the selector to get propertyId
import DateRangePicker from '../../components/DateRangePicker';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, Grid, Card, Typography } from '@mui/material';
import {
  ScatterChart,
  Scatter,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { subDays } from 'date-fns';

const GoogleAdsPage = () => {
  const dispatch = useDispatch();

  // Retrieve the currently selected propertyId from Redux
  const propertyId = useSelector(selectPropertyId);
  const { data, status, error } = useSelector((state) => state.googleAds);

  // Dynamic default dates (90 days before today and today)
  const today = new Date();
  const ninetyDaysAgo = subDays(today, 90);
  const defaultStartDate = ninetyDaysAgo.toISOString().split('T')[0];
  const defaultEndDate = today.toISOString().split('T')[0];

  const [dates, setDates] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });

  const [costInput, setCostInput] = useState('');

  // Debug logs
  console.log('[GoogleAdsPage] Rendered');
  console.log('[GoogleAdsPage] propertyId:', propertyId);
  console.log('[GoogleAdsPage] dates:', dates);
  console.log('[GoogleAdsPage] data from store:', data);
  console.log('[GoogleAdsPage] status:', status, 'error:', error);

  // Fetch data when dates or propertyId change
  useEffect(() => {
    console.log('[useEffect] Checking conditions for fetchGoogleAdsData...');
    console.log(' - propertyId:', propertyId);
    console.log(' - startDate:', dates.startDate);
    console.log(' - endDate:', dates.endDate);
    if (dates.startDate && dates.endDate && propertyId) {
      console.log('[useEffect] Conditions met, dispatching fetchGoogleAdsData...');
      dispatch(
        fetchGoogleAdsData({
          startDate: dates.startDate,
          endDate: dates.endDate,
          propertyId,
        })
      );
    } else {
      console.log('[useEffect] Conditions not met, not dispatching.');
    }
  }, [dates, propertyId, dispatch]);

  const handlePredict = () => {
    console.log('[handlePredict] Clicked Predict Revenue button');
    console.log(' - costInput:', costInput);
    console.log(' - propertyId:', propertyId);
    console.log(' - startDate:', dates.startDate);
    console.log(' - endDate:', dates.endDate);
    if (costInput && propertyId && dates.startDate && dates.endDate) {
      console.log('[handlePredict] Conditions met, dispatching predictGoogleAds...');
      dispatch(
        predictGoogleAds({
          costInput,
          startDate: dates.startDate,
          endDate: dates.endDate,
          propertyId,
        })
      );
    } else {
      console.log('[handlePredict] Conditions not met, not dispatching.');
    }
  };

  if (status === 'loading') {
    console.log('[render] status is loading, showing "Loading data..."');
    return <div>Loading data...</div>;
  }

  if (error) {
    console.log('[render] error occurred:', error);
    return <div>Error: {error}</div>;
  }

  if (!data) {
    console.log('[render] no data available.');
    return <div>No data available.</div>;
  }

  const {
    correlation_coefficient,
    overall_roas,
    CAC,
    AOV,
    currency_symbol,
    data: tableData,
    revenue_prediction,
    roas_prediction,
    cost_input: predictedCostInput,
  } = data;

  // Prepare data for Recharts
  const scatterRevenueData = tableData.map((item) => ({
    cost: item.cost,
    revenue: item.revenue,
    tooltip: `Date: ${item.date}, Cost: ${currency_symbol}${item.cost.toFixed(
      2
    )}, Revenue: ${currency_symbol}${item.revenue.toFixed(2)}`,
    isOutlier: item.is_outlier,
  }));

  const scatterRoasData = tableData.map((item) => ({
    cost: item.cost,
    roas: item.cost === 0 ? 0 : item.revenue / item.cost,
    tooltip: `Date: ${item.date}, Cost: ${currency_symbol}${item.cost.toFixed(
      2
    )}, ROAS: ${(
      item.cost === 0 ? 0 : item.revenue / item.cost
    ).toFixed(2)}`,
    isOutlier: item.is_outlier,
  }));

  const columns = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'sourceMedium', headerName: 'Source / Medium', width: 200 },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 100,
      valueFormatter: (params) =>
        `${currency_symbol}${params.value.toFixed(2)}`,
    },
    { field: 'conversions', headerName: 'Conversions', width: 120 },
    {
      field: 'revenue',
      headerName: 'Revenue',
      width: 100,
      valueFormatter: (params) =>
        `${currency_symbol}${params.value.toFixed(2)}`,
    },
    {
      field: 'roas',
      headerName: 'ROAS',
      width: 100,
      valueFormatter: (params) =>
        params.value === 'undefined' ? 'undefined' : params.value.toFixed(2),
    },
  ];

  const rows = tableData.map((item, index) => ({
    id: index,
    date: item.date,
    sourceMedium: '-',
    cost: item.cost,
    conversions: '-',
    revenue: item.revenue,
    roas: item.cost === 0 ? 'undefined' : item.revenue / item.cost,
  }));

  return (
    <div style={{ padding: '20px' }}>
      <h1>Google Ads Cost vs. Revenue</h1>
      <DateRangePicker
        onDateChange={(newDates) => {
          console.log('[DateRangePicker] onDateChange called with:', newDates);
          setDates({
            startDate: newDates.startDate || defaultStartDate,
            endDate: newDates.endDate || defaultEndDate,
          });
        }}
      />

      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Correlation Coefficient</Typography>
            <Typography variant="body1">{correlation_coefficient}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Overall ROAS</Typography>
            <Typography variant="body1">{overall_roas}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">CAC</Typography>
            <Typography variant="body1">{currency_symbol}{CAC}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">AOV</Typography>
            <Typography variant="body1">{currency_symbol}{AOV}</Typography>
          </Card>
        </Grid>
      </Grid>

      {/* Recharts and DataGrid logic remains the same */}
    </div>
  );
};

export default GoogleAdsPage;
