// src/pages/ga4/AudiencePage.js

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAudienceTotals } from "../../features/ga4/audienceTotalSlice";
import { fetchAudienceDailyData, fetchAudienceWeeklyData, fetchAudienceMonthlyData, clearData } from "../../features/ga4/audienceChartSlice";
import DateRangePicker from "../../components/DateRangePicker";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Skeleton, Grid, Box, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, Paper } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { formatNumber, formatDate } from "../../utils/numberUtils";
import { selectPropertyId } from "../../store/selectors";
import { DataGrid } from "@mui/x-data-grid";

const AudiencePage = () => {
  const dispatch = useDispatch();
  const { audienceData, status, error } = useSelector((state) => state.audienceTotal);
  const { currentPeriodData, previousPeriodData, status: chartStatus } = useSelector((state) => state.audienceChart);
  const propertyId = useSelector(selectPropertyId);
  const dateRange = useSelector((state) => state.dateRange);

  const [dates, setDates] = useState({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    prevStartDate: dateRange.prevStartDate,
    prevEndDate: dateRange.prevEndDate,
    isComparisonEnabled: dateRange.isComparisonEnabled,
  });
  const [activeTab, setActiveTab] = useState("daily");

  const metrics = [
    { label: "Active Users", key: "activeUsers", type: "integer" },
    { label: "New Users", key: "newUsers", type: "integer" },
    { label: "Sessions", key: "sessions", type: "integer" },
    { label: "Pageviews", key: "screenPageViews", type: "integer" },
    { label: "Avg. Session Duration", key: "averageSessionDuration", type: "duration" },
    { label: "Engagement Rate", key: "engagementRate", type: "percentage" },
    { label: "Sessions/User", key: "sessionsPerUser", type: "decimal" },
    { label: "Pageviews/Session", key: "screenPageViewsPerSession", type: "decimal" },
  ];

  const [selectedMetric, setSelectedMetric] = useState("activeUsers");

  // Updated state for pagination model
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const fetchData = useCallback(async () => {
    if (!propertyId || !dates.startDate || !dates.endDate) return;
    try {
      await dispatch(
        fetchAudienceTotals({
          startDate: dates.startDate,
          endDate: dates.endDate,
          prevStartDate: dates.isComparisonEnabled ? dates.prevStartDate : null,
          prevEndDate: dates.isComparisonEnabled ? dates.prevEndDate : null,
          isComparisonEnabled: dates.isComparisonEnabled,
        })
      );

      const actionPayload = {
        propertyId,
        startDate: dates.startDate,
        endDate: dates.endDate,
        prevStartDate: dates.isComparisonEnabled ? dates.prevStartDate : null,
        prevEndDate: dates.isComparisonEnabled ? dates.prevEndDate : null,
        filterOrganic: false,
        isComparisonEnabled: dates.isComparisonEnabled,
      };

      if (activeTab === "daily") {
        await dispatch(fetchAudienceDailyData(actionPayload));
      } else if (activeTab === "weekly") {
        await dispatch(fetchAudienceWeeklyData(actionPayload));
      } else if (activeTab === "monthly") {
        await dispatch(fetchAudienceMonthlyData(actionPayload));
      }
    } catch (error) {
      console.error("Failed to fetch audience totals:", error);
    }
  }, [dispatch, propertyId, dates, activeTab]);

  useEffect(() => {
    dispatch(clearData());
    fetchData();
  }, [fetchData, activeTab, dispatch]);

  const handleDateChange = ({ startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled }) => {
    setDates({
      startDate: startDate || null,
      endDate: endDate || null,
      prevStartDate: isComparisonEnabled ? prevStartDate : null,
      prevEndDate: isComparisonEnabled ? prevEndDate : null,
      isComparisonEnabled: isComparisonEnabled,
    });
  };

  const renderMetrics = () => {
    if (!audienceData || !audienceData.totals || !audienceData.totals.current) {
      return <div>No data available.</div>;
    }

    return (
      <Grid container spacing={2}>
        {metrics.map((metric, index) => {
          const current = audienceData?.totals?.current?.[metric.key] || 0;
          const previous = audienceData?.totals?.previous?.[metric.key] || 0;
          const diff = audienceData?.percent_differences?.[metric.key] || 0;
          const diffClass = diff > 0 ? "green-arrow" : diff < 0 ? "red-arrow" : "";

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <div className="card">
                <h3>{metric.label}</h3>
                <p>{formatNumber(current, metric.type)}</p>
                {dates.isComparisonEnabled && (
                  <small>
                    ({formatNumber(previous, metric.type)})
                    {previous !== 0 && (
                      <span className={diffClass}>
                        {diff > 0 ? "↑" : diff < 0 ? "↓" : ""} {Math.abs(diff).toFixed(2)}%
                      </span>
                    )}
                  </small>
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderSkeletons = () => {
    const skeletons = Array.from(new Array(8)).map((_, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <div className="card">
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="rectangular" height={60} />
        </div>
      </Grid>
    ));
    return (
      <Grid container spacing={2}>
        {skeletons}
      </Grid>
    );
  };

const renderSkeletonsChart = () => {
  const skeletons = Array.from(new Array(1)).map((_, index) => (
    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
        <Skeleton variant="text" height={80} />
        <Skeleton variant="text" height={30} />
        <Skeleton variant="rectangular" height={200} />
    </Grid>
  ));
  return (
    <Grid container spacing={2}>
      {skeletons}
    </Grid>
  );
};


  // Date parsing functions
  function parseDateKey(dateKey, dateValue) {
    if (dateValue === undefined || dateValue === null || dateValue === "") {
      console.error(`parseDateKey: dateValue is undefined or null for dateKey: ${dateKey}`);
      return null;
    }

    if (dateKey === "date") {
      const year = parseInt(dateValue.slice(0, 4), 10);
      const month = parseInt(dateValue.slice(4, 6), 10) - 1;
      const day = parseInt(dateValue.slice(6, 8), 10);
      const date = new Date(year, month, day);
      if (isNaN(date.getTime())) {
        console.error(`Invalid date created in parseDateKey for dateKey: ${dateKey}, dateValue: ${dateValue}`);
        return null;
      }
      return date;
    } else if (dateKey === "yearWeek") {
      const year = parseInt(dateValue.slice(0, 4), 10);
      const week = parseInt(dateValue.slice(4, 6), 10);
      const firstDayOfYear = new Date(year, 0, 1);
      const daysOffset = (week - 1) * 7;
      const date = new Date(firstDayOfYear.getTime() + daysOffset * 24 * 60 * 60 * 1000);
      if (isNaN(date.getTime())) {
        console.error(`Invalid date created in parseDateKey for dateKey: ${dateKey}, dateValue: ${dateValue}`);
        return null;
      }
      return date;
    } else if (dateKey === "yearMonth") {
      const year = parseInt(dateValue.slice(0, 4), 10);
      const month = parseInt(dateValue.slice(4, 6), 10) - 1;
      const date = new Date(year, month, 1);
      if (isNaN(date.getTime())) {
        console.error(`Invalid date created in parseDateKey for dateKey: ${dateKey}, dateValue: ${dateValue}`);
        return null;
      }
      return date;
    }
  }

  function formatDateKey(dateKey, date) {
    const year = date.getFullYear().toString();
    if (dateKey === "date") {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return year + month + day;
    } else if (dateKey === "yearWeek") {
      const weekNumber = getWeekNumber(date);
      const weekStr = ("0" + weekNumber).slice(-2);
      return year + weekStr;
    } else if (dateKey === "yearMonth") {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      return year + month;
    }
  }

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  function dateDifference(date1, date2, unit) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    const timeDiff = dt1.getTime() - dt2.getTime();

    if (unit === "days") {
      return Math.round(timeDiff / (1000 * 3600 * 24));
    } else if (unit === "weeks") {
      return Math.round(timeDiff / (1000 * 3600 * 24 * 7));
    } else if (unit === "months") {
      return (dt1.getFullYear() - dt2.getFullYear()) * 12 + dt1.getMonth() - dt2.getMonth();
    } else {
      return timeDiff;
    }
  }

  const renderCharts = (mergedData, dateKey) => {
    if (!mergedData || mergedData.length === 0) {
      return <div>No data available.</div>;
    }

    const metric = metrics.find((m) => m.key === selectedMetric);

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        const currentValue = payload[0].value;
        const previousValueKey = `previous_${payload[0].dataKey}`;
        const previousValue = dataPoint[previousValueKey];

        return (
          <div className="custom-tooltip">
            <p>{`Date: ${formatDate(parseDateKey(dateKey, dataPoint.date), "MMM DD, YYYY")}`}</p>
            <p>{`Current ${payload[0].name}: ${formatNumber(currentValue, metric.type)}`}</p>
            {dates.isComparisonEnabled && (
              <>
                <p>{`Previous Date: ${formatDate(parseDateKey(dateKey, dataPoint.previous_date), "MMM DD, YYYY")}`}</p>
                <p>{`Previous ${payload[0].name}: ${formatNumber(previousValue, metric.type)}`}</p>
              </>
            )}
          </div>
        );
      }
      return null;
    };

    return (
      <div style={{ marginBottom: "20px" }}>
        <h3>{metric.label}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={mergedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
  dataKey="date"
  tickFormatter={(tick) => {
    const date = parseDateKey(dateKey, tick);
    if (!date) return "";

    if (dateKey === "date") {
      return formatDate(date, "MMM DD");
    } else if (dateKey === "yearWeek") {
      const startOfWeek = new Date(date);
      const endOfWeek = new Date(date);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Add 6 days to get the end of the week

      // Format start and end dates without repeating the year or month if the same
      const startMonth = formatDate(startOfWeek, "MMM");
      const endMonth = formatDate(endOfWeek, "MMM");
      const startDay = formatDate(startOfWeek, "DD");
      const endDay = formatDate(endOfWeek, "DD");

      if (startMonth === endMonth) {
        return `${startMonth} ${startDay} - ${endDay}`;
      } else {
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
      }
    } else if (dateKey === "yearMonth") {
      return formatDate(date, "MMM YYYY");
    }

    return tick;
  }}
/>

            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey={metric.key} name={`Current ${metric.label}`} stroke="#8884d8" activeDot={{ r: 8 }} />
            {dates.isComparisonEnabled && <Line type="monotone" dataKey={`previous_${metric.key}`} name={`Previous ${metric.label}`} stroke="#82ca9d" />}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const mergeData = (current, previous, dateKey, dateDiff, dates) => {
    const merged = current
      .map((item) => {
        const currentDateStr = item[dateKey];
        if (!currentDateStr) {
          return null; // Skip this item
        }

        const currentDate = parseDateKey(dateKey, currentDateStr);
        if (!currentDate) {
          return null; // Skip this item
        }

        let previousDateStr = null;
        let previousItem = null;

        // Calculate previous date
        let previousDate;
        if (dates.isComparisonEnabled && dateDiff) {
          if (dateKey === "date") {
            previousDate = new Date(currentDate.getTime() - dateDiff * 24 * 3600 * 1000);
          } else if (dateKey === "yearWeek") {
            previousDate = new Date(currentDate.getTime() - dateDiff * 7 * 24 * 3600 * 1000);
          } else if (dateKey === "yearMonth") {
            previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - dateDiff, 1);
          }

          previousDateStr = formatDateKey(dateKey, previousDate);

          // Find the previous period data point
          previousItem = previous.find((prevItem) => prevItem[dateKey] === previousDateStr);
        }

        const mergedItem = {
          ...item,
          date: currentDateStr,
        };

        if (dates.isComparisonEnabled) {
          mergedItem.previous_date = previousDateStr || "-";
          if (previousItem) {
            metrics.forEach((metric) => {
              mergedItem[`previous_${metric.key}`] = previousItem[metric.key] || null;
            });
          } else {
            metrics.forEach((metric) => {
              mergedItem[`previous_${metric.key}`] = null;
            });
          }
        }

        return mergedItem;
      })
      .filter(Boolean);

    merged.sort((a, b) => {
      const dateA = parseDateKey(dateKey, a.date);
      const dateB = parseDateKey(dateKey, b.date);
      return dateA - dateB;
    });

    return merged;
  };

  const renderTabContent = () => {
    const data = {
      current_period: currentPeriodData || [],
      previous_period: previousPeriodData || [],
    };

    let dateKey = "date";
    let unit = "days";
    if (activeTab === "weekly") {
      dateKey = "yearWeek";
      unit = "weeks";
    } else if (activeTab === "monthly") {
      dateKey = "yearMonth";
      unit = "months";
    }

    const dateDiff = dateDifference(dates.startDate, dates.prevStartDate, unit);
    const mergedData = mergeData(data.current_period, data.previous_period || [], dateKey, dateDiff, dates);

    return (
      <div>
        <div className="row mt-24">
          <div className="col-7 col-lg-12 mb-24">
            <div className="chart-wrap">
              <div className="overview_tab">
                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                  <Tab label="Daily" value="daily" />
                  <Tab label="Weekly" value="weekly" />
                  <Tab label="Monthly" value="monthly" />
                </Tabs>
                <FormControl variant="outlined" fullWidth style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <InputLabel id="metric-select-label">Metric</InputLabel>
                  <Select labelId="metric-select-label" id="metric-select" value={selectedMetric} onChange={(e) => setSelectedMetric(e.target.value)} label="Metric">
                    {metrics.map((metric) => (
                      <MenuItem key={metric.key} value={metric.key}>
                        {metric.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {renderCharts(mergedData, dateKey)}
              </div>
            </div>
          </div>
          <div className="col-5 col-lg-12 mb-24">
            <div className="chart-wrap"></div>
          </div>
          <div className="col-12 col-lg-12">
            <div className="chart-wrap">
            {renderDataTable(mergedData, dateKey)}        
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDataTable = (mergedData, dateKey) => {
    if (!mergedData || mergedData.length === 0) {
      return <div>No data available.</div>;
    }

    const columns = [
      {
        field: "date",
        headerName: "Date",
        width: 150,
        sortable: true,
        renderCell: (params) => {
          const date = parseDateKey(dateKey, params.value);
          if (!date) return "-";
          if (dateKey === "date") {
            return formatDate(date, "MMM DD, YYYY");
          } else if (dateKey === "yearWeek") {
            return `W${getWeekNumber(date)} ${date.getFullYear()}`;
          } else if (dateKey === "yearMonth") {
            return formatDate(date, "MMM YYYY");
          }
          return params.value;
        },
      },
    ];

    if (dates.isComparisonEnabled) {
      columns.push({
        field: "previous_date",
        headerName: "Previous Date",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          const date = parseDateKey(dateKey, params.value);
          if (!date) return "-";
          if (dateKey === "date") {
            return formatDate(date, "MMM DD, YYYY");
          } else if (dateKey === "yearWeek") {
            return `W${getWeekNumber(date)} ${date.getFullYear()}`;
          } else if (dateKey === "yearMonth") {
            return formatDate(date, "MMM YYYY");
          }
          return params.value;
        },
      });
    }

    metrics.forEach((metric) => {
      // Current period metric
      columns.push({
        field: metric.key,
        headerName: metric.label,
        width: 130,
        sortable: true,
        renderCell: (params) => formatNumber(params.value, metric.type),
      });

      if (dates.isComparisonEnabled) {
        // Previous period metric
        columns.push({
          field: `previous_${metric.key}`,
          headerName: `Previous ${metric.label}`,
          width: 130,
          sortable: false,
          renderCell: (params) => formatNumber(params.value, metric.type),
        });

        // Percentage change
        columns.push({
          field: `change_${metric.key}`,
          headerName: `Change (%)`,
          width: 100,
          sortable: false,
          renderCell: (params) => {
            const change = params.value;
            return change !== null && change !== undefined ? `${change.toFixed(2)}%` : "-";
          },
        });
      }
    });

    // Prepare the rows using mergedData
    const rows = mergedData.map((item, index) => {
      const row = {
        id: index,
        date: item.date,
      };

      if (dates.isComparisonEnabled) {
        row["previous_date"] = item["previous_date"];
      }

      metrics.forEach((metric) => {
        row[metric.key] = item[metric.key];

        if (dates.isComparisonEnabled) {
          const previousValue = item[`previous_${metric.key}`];
          row[`previous_${metric.key}`] = previousValue;
          const currentValue = item[metric.key];
          let change = null;
          if (previousValue != null && previousValue !== 0) {
            change = ((currentValue - previousValue) / previousValue) * 100;
          }
          row[`change_${metric.key}`] = change;
        }
      });

      return row;
    });

    return (
      <div style={{ width: "100%", marginTop: "20px" }}>
        <Paper elevation={3} style={{ boxShadow: "none" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowsPerPageOptions={[10, 25, 50]}
            autoHeight
            disableSelectionOnClick
          />
        </Paper>
      </div>
    );
  };

  return (
    <div className="container">
      <Navbar />
      <div className="main">
        <Sidebar />
        <div className="content">
          <div className="title-filter">
            <h1>Audience Overview</h1>
            <div className="date-pickers-block">
              <DateRangePicker onDateChange={handleDateChange} />
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          {status === "loading" || chartStatus === "loading" ? renderSkeletons() : renderMetrics()}

          {status === "loading" || chartStatus === "loading" ? renderSkeletonsChart() : renderTabContent()}
          
        </div>
      </div>
    </div>
  );
};

export default AudiencePage;
