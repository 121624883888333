import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchCampaignData } from "../../features/ga4/campaignThunks";
import { setFilters } from "../../features/ga4/campaignSlice";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import DateRangePicker from "../DateRangePicker";
import { Skeleton, Select, MenuItem, FormControl, InputLabel, Paper } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { DataGrid } from "@mui/x-data-grid";
import { subDays } from "date-fns";

const CampaignComponent = ({ moduleName, moduleTitle }) => {
  const dispatch = useDispatch();

  // Redux state
  const moduleState = useSelector((state) => state.campaign, shallowEqual);

  const {
    chartData = [],
    metrics = [],
    sourceMediumValues = [], // Dropdown options for Source/Medium
    dimensions = [],
    loading = false,
    error,
    selectedMetric,
    selectedSourceMedium,
    currentData = [],
    dates: reduxDates = {}, // Fallback for Redux dates
  } = moduleState;

  // Local state for dates with default values
  const [dates, setDates] = useState({
    startDate: reduxDates.startDate || subDays(new Date(), 29).toISOString().split("T")[0],
    endDate: reduxDates.endDate || new Date().toISOString().split("T")[0],
    isComparisonEnabled: reduxDates.isComparisonEnabled || false,
    prevStartDate: reduxDates.prevStartDate || null,
    prevEndDate: reduxDates.prevEndDate || null,
  });

  // Set default filters
  useEffect(() => {
    if (!selectedMetric && metrics.length > 0) {
      dispatch(setFilters({ metric: metrics[0].friendly }));
    }
    if (!selectedSourceMedium) {
      dispatch(setFilters({ sourceMedium: "All" }));
    }
  }, [dispatch, selectedMetric, metrics, selectedSourceMedium]);
  // Updated state for pagination model
      const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  // Fetch data
  const fetchData = useCallback(() => {
    if (!dates.startDate || !dates.endDate) return;

    dispatch(
      fetchCampaignData({
        startDate: dates.startDate,
        endDate: dates.endDate,
        prevStartDate: dates.isComparisonEnabled ? dates.prevStartDate : null,
        prevEndDate: dates.isComparisonEnabled ? dates.prevEndDate : null,
        sourceMedium: selectedSourceMedium !== "All" ? selectedSourceMedium : null,
      })
    );
  }, [dispatch, dates, selectedSourceMedium]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle date changes
  const handleDateChange = (newDates) => {
    setDates(newDates);
    dispatch(setFilters({ ...newDates }));
  };

  const handleMetricChange = (e) => {
    dispatch(setFilters({ metric: e.target.value }));
  };

  const handleSourceMediumChange = (e) => {
    dispatch(setFilters({ sourceMedium: e.target.value }));
  };

  // Utility to parse numeric values
  const parseNumber = (value) => {
    if (value === undefined || value === null) return 0;
    const parsedValue = parseFloat(value.toString().replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  // Render charts
  const renderCharts = () => {
    const dimensionFriendlyName = dimensions[0]?.friendly || "Campaign Name";

    const filteredData = chartData
      .filter((item) => item.metric_name === selectedMetric)
      .map((item) => ({
        ...item,
        current: parseNumber(item.current),
        previous: parseNumber(item.previous),
      }))
      .sort((a, b) => b.current - a.current)
      .slice(0, 15);

    if (!filteredData.length) {
      return <div>No chart data available for the selected Source/Medium and metric.</div>;
    }

    return (
      <>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dimensionFriendlyName} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="current" name="Current Period" fill="#8884d8" />
            {dates.isComparisonEnabled && <Bar dataKey="previous" name="Previous Period" fill="#82ca9d" />}
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  };

  // Render table
  const renderTable = () => {
    const data = currentData || [];
    const dimensionFriendlyName = dimensions[0]?.friendly || "Campaign Name";

    if (!data.length) {
      return <div>No table data available for the selected Source/Medium.</div>;
    }

    const columns = [
      {
        field: dimensionFriendlyName,
        headerName: dimensionFriendlyName,
        width: 200,
      },
      ...metrics.map((metric) => ({
        field: metric.friendly,
        headerName: metric.friendly,
        width: 150,
      })),
    ];

    // Sort a copy of the data by the first metric in descending order
    const firstMetricFriendlyName = metrics[0]?.friendly;
    const sortedData = [...data].sort((a, b) => parseNumber(b[firstMetricFriendlyName]) - parseNumber(a[firstMetricFriendlyName]));

    const rows = sortedData.map((item, index) => {
      const row = { id: index };
      row[dimensionFriendlyName] = item[dimensionFriendlyName];
      metrics.forEach((metric) => {
        row[metric.friendly] = parseNumber(item[metric.friendly]);
      });
      return row;
    });

    return (
      <Paper elevation={3} style={{ marginTop: "20px", boxShadow: "none" }}>
        {/* <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10, 25, 50]} autoHeight disableSelectionOnClick /> */}
        <DataGrid
            rows={rows}
            columns={columns}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowsPerPageOptions={[10, 25, 50]}
            autoHeight
            disableSelectionOnClick
          />
      </Paper>
    );
  };

  return (
    <div className="container">
      <div className="main">
        <Sidebar />
        <Navbar />
        <div className="content">
          <div className="title-filter">
            <h1>{moduleTitle}</h1>
            <div className="date-pickers-block">
              <DateRangePicker onDateChange={handleDateChange} />
            </div>
          </div>
          <div className="filters">
            <FormControl style={{ minWidth: 120, marginRight: "16px" }}>
              <InputLabel>Source/Medium</InputLabel>
              <Select value={selectedSourceMedium || ""} onChange={handleSourceMediumChange} disabled={!sourceMediumValues.length}
              label="Dimension"
               sx={{
                backgroundColor: "white",
                height: 40, 
              }}
              >
                {sourceMediumValues.map((source) => (
                  <MenuItem key={source} value={source}>
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel>Metric</InputLabel>
              <Select value={selectedMetric || ""} onChange={handleMetricChange} disabled={!metrics.length}
              label="Metric"
               sx={{
                backgroundColor: "white",
                height: 40, 
              }}
              >
                {metrics.map((metric) => (
                  <MenuItem key={metric.friendly} value={metric.friendly}>
                    {metric.friendly}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {error && <div className="error-message">{JSON.stringify(error)}</div>}
          <div className="row mt-32">
            <div className="col-7 col-lg-12 mb-32 mb-lg-20">
              <div className="chart-wrap">{loading ? <Skeleton variant="rectangular" height={400} /> : renderCharts()}</div>
            </div>
            <div className="col-5 col-lg-12 mb-32 mb-lg-20">
              <div className="chart-wrap"></div>
            </div>
            <div className="col-12 col-lg-12">
              <div className="chart-wrap">
                <div className="table-wrap">{loading ? <Skeleton variant="rectangular" height={400} /> : renderTable()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignComponent;
