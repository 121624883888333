import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance'; 

// Async action to check authorization for GA4
export const checkAuthorization = createAsyncThunk(
  'ga4/checkAuthorization',
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.get('/api/ga4/check_authorization/');

      // If the backend says we need to connect GA4
      // e.g. { missing_credentials: true, redirect: "/connect-ga4-account" }
      if (response.data.missing_credentials) {
        window.location.href = response.data.redirect || '/connect-ga4-account';
        // Return null so Redux sees no "error" to store
        return rejectWithValue(null);
      }

      // If there's a separate "redirect" field for another reason
      if (response.data.redirect) {
        window.location.href = response.data.redirect;
        return rejectWithValue(null);
      }

      // Otherwise, everything’s fine—just return the data
      // e.g. { message: "Authorized successfully." }
      return response.data;
    } catch (error) {
      const { response } = error;

      // If the backend used a 401 or 403 approach instead
      // (Though ideally you're now returning 200 with missing_credentials)
      if (response?.status === 403 && response.data?.redirect) {
        window.location.href = response.data.redirect;
        return rejectWithValue(null);
      } else if (response?.status === 401) {
        window.location.href = '/login';
        return rejectWithValue(null);
      }

      // Otherwise fallback to a “real” error message
      const fallbackError = response?.data?.error || 'Failed to check GA4 authorization.';
      return rejectWithValue(fallbackError);
    }
  }
);

const ga4Slice = createSlice({
  name: 'ga4',
  initialState: {
    properties: [],
    accounts: [],
    hasGa4Property: false,
    loading: false,
    error: null,
  },
  reducers: {
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    resetGa4State: (state) => {
      state.properties = [];
      state.accounts = [];
      state.hasGa4Property = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state for checking authorization
      .addCase(checkAuthorization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle fulfilled state for checking authorization
      .addCase(checkAuthorization.fulfilled, (state, action) => {
        state.loading = false;
        // e.g. { message: "Authorized successfully." }
        if (action.payload?.message === 'Authorized successfully.') {
          state.hasGa4Property = true;
        } else {
          state.hasGa4Property = false;
        }
      })
      // Handle rejected state for checking authorization
      .addCase(checkAuthorization.rejected, (state, action) => {
        state.loading = false;
        state.hasGa4Property = false;

        // If we explicitly returned null above (missing_cred or forced redirect),
        // skip storing an “error” in Redux
        if (action.payload === null) {
          state.error = null;
        } else {
          // Otherwise treat it as a normal error
          state.error = action.payload || 'Failed to check GA4 authorization.';
        }
      });
  },
});

export const { setProperties, setAccounts, resetGa4State } = ga4Slice.actions;
export default ga4Slice.reducer;
