// src/components/DateRangePicker.js

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogContent, Box, Switch, FormControlLabel } from "@mui/material";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";
import { setDateRange, toggleComparison } from "../features/date/dateSlice";
import { addDays, subDays, subMonths, subYears, differenceInDays, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";

function DateRangePicker({ onDateChange }) {
  const dispatch = useDispatch();
  const { startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled } = useSelector((state) => state.dateRange);

  const [currentRange, setCurrentRange] = useState([startDate ? new Date(startDate) : subDays(new Date(), 29), endDate ? new Date(endDate) : new Date()]);

  const [compareEnabled, setCompareEnabled] = useState(isComparisonEnabled);
  const [previousRange, setPreviousRange] = useState([prevStartDate ? new Date(prevStartDate) : null, prevEndDate ? new Date(prevEndDate) : null]);

  const [currentPeriodOpen, setCurrentPeriodOpen] = useState(false);
  const [previousPeriodOpen, setPreviousPeriodOpen] = useState(false);

  const handleCurrentPeriodOpen = () => setCurrentPeriodOpen(true);
  const handlePreviousPeriodOpen = () => setPreviousPeriodOpen(true);
  const handleCurrentPeriodClose = () => setCurrentPeriodOpen(false);
  const handlePreviousPeriodClose = () => setPreviousPeriodOpen(false);

  const formatDate = (date) => date.toISOString().split("T")[0];
  const formatDateWithoutWeekday = (date) => date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });

  const toggleComparisonHandler = () => {
    const updatedCompareEnabled = !compareEnabled;
    setCompareEnabled(updatedCompareEnabled);
    dispatch(toggleComparison());

    if (updatedCompareEnabled) {
      const daysDifference = differenceInDays(currentRange[1], currentRange[0]) + 1;
      const newPrevStart = subDays(currentRange[0], daysDifference);
      const newPrevEnd = subDays(currentRange[0], 1);
      setPreviousRange([newPrevStart, newPrevEnd]);
      updateDateRange(currentRange, [newPrevStart, newPrevEnd], updatedCompareEnabled);
    } else {
      setPreviousRange([null, null]);
      updateDateRange(currentRange, [null, null], updatedCompareEnabled);
    }
  };

  const comparisonPresets = useMemo(() => {
    if (!currentRange[0] || !currentRange[1]) return [];
    return [
      { label: "Previous Period", range: [subDays(currentRange[0], differenceInDays(currentRange[1], currentRange[0]) + 1), subDays(currentRange[0], 1)] },
      { label: "Same Period Last Month", range: [subMonths(currentRange[0], 1), subMonths(currentRange[1], 1)] },
      { label: "Same Period Last Year", range: [subYears(currentRange[0], 1), subYears(currentRange[1], 1)] },
    ];
  }, [currentRange]);

  const renderPresets = (presets, setRange, closeDialog) => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, p: 2, width: "200px" }}>
      {presets.map((preset) => (
        <Button
          key={preset.label}
          onClick={() => {
            setRange(preset.range);
            closeDialog();
            handleApply();
          }}
          sx={{
            justifyContent: "flex-start",
            textTransform: "none",
            padding: "4px 0",
            minWidth: "100%",
            lineHeight: 2,
            border: "none",
          }}>
          {preset.label}
        </Button>
      ))}
    </Box>
  );

  const currentPeriodPresets = [
    { label: "Today", range: [new Date(), new Date()] },
    { label: "Yesterday", range: [addDays(new Date(), -1), addDays(new Date(), -1)] },
    { label: "Last 7 days", range: [addDays(new Date(), -6), new Date()] },
    { label: "Last 30 days", range: [addDays(new Date(), -29), new Date()] },
    { label: "This Month", range: [startOfMonth(new Date()), new Date()] },
    { label: "Last Month", range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
    { label: "This Year", range: [startOfYear(new Date()), new Date()] },
    { label: "Last Year", range: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
  ];

  const handleApply = () => {
    updateDateRange(currentRange, previousRange, compareEnabled);
  };

  const handleCurrentDateChange = (newValue) => {
    setCurrentRange(newValue);
    if (newValue[0] && newValue[1]) handleCurrentPeriodClose();
  };

  const handlePreviousDateChange = (newValue) => {
    setPreviousRange(newValue);
    if (newValue[0] && newValue[1]) handlePreviousPeriodClose();
  };

  useEffect(() => {
    if (compareEnabled && currentRange[0] && currentRange[1]) {
      const daysDifference = differenceInDays(currentRange[1], currentRange[0]) + 1;
      const newPrevStart = subDays(currentRange[0], daysDifference);
      const newPrevEnd = subDays(currentRange[0], 1);
      setPreviousRange([newPrevStart, newPrevEnd]);
    }
  }, [currentRange, compareEnabled]);

  useEffect(() => {
    if (!startDate && !endDate) {
      handleApply();
    }
  }, []);

  const updateDateRange = (currentRange, previousRange, compareEnabled) => {
    const datePayload = {
      startDate: formatDate(currentRange[0]) || null,
      endDate: formatDate(currentRange[1]) || null,
      prevStartDate: compareEnabled && previousRange[0] ? formatDate(previousRange[0]) : null,
      prevEndDate: compareEnabled && previousRange[1] ? formatDate(previousRange[1]) : null,
      isComparisonEnabled: compareEnabled,
    };
    dispatch(setDateRange(datePayload));
    if (onDateChange) onDateChange(datePayload);
  };

  return (
    <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button variant="outlined" 
        onClick={handleCurrentPeriodOpen}
        sx={{
          textTransform: "none",
          backgroundColor: "white",
          height: 35, 
        }}
        >
          {currentRange[0] && currentRange[1]
            ? `${formatDateWithoutWeekday(currentRange[0])} - ${formatDateWithoutWeekday(currentRange[1])}`
            : "Select Date Range"}
        </Button>

        {compareEnabled && previousRange[0] && previousRange[1] && (
          <Button variant="outlined" onClick={handlePreviousPeriodOpen}
          sx={{
            textTransform: "none",
            backgroundColor: "white",
            height: 35, 
          }}
          >
            {`${formatDateWithoutWeekday(previousRange[0])} - ${formatDateWithoutWeekday(previousRange[1])}`}
          </Button>
        )}
      </Box>

      <FormControlLabel control={<Switch checked={compareEnabled} onChange={toggleComparisonHandler} color="primary" />} label="Enable Comparison Period" />

      <Dialog open={currentPeriodOpen} onClose={handleCurrentPeriodClose} maxWidth="md">
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {renderPresets(currentPeriodPresets, setCurrentRange, handleCurrentPeriodClose)}
              <StaticDateRangePicker displayStaticWrapperAs="desktop" value={currentRange} onChange={handleCurrentDateChange} calendars={2} />
            </Box>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>

      {compareEnabled && previousRange[0] && previousRange[1] && (
        <Dialog open={previousPeriodOpen} onClose={handlePreviousPeriodClose} maxWidth="md">
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {renderPresets(comparisonPresets, setPreviousRange, handlePreviousPeriodClose)}
                <StaticDateRangePicker displayStaticWrapperAs="desktop" value={previousRange} onChange={handlePreviousDateChange} calendars={2} />
              </Box>
            </LocalizationProvider>
          </DialogContent>
        </Dialog>
      )}

      <Button variant="contained" onClick={handleApply} sx={{ textTransform: "none", alignSelf: "flex-start" }}>
        Apply
      </Button>
    </Box>
  );
}

export default React.memo(DateRangePicker);
