import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance'; // Import the custom Axios instance

// Async thunk to fetch initial Google Ads analysis data
// fetchGoogleAdsData
export const fetchGoogleAdsData = createAsyncThunk(
    'googleAds/fetchGoogleAdsData',
    async ({ startDate, endDate, propertyId }, { rejectWithValue }) => {
      try {
        const params = {};
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;
        if (propertyId) params.property_id = propertyId; // Add property_id
  
        const response = await backendAxiosInstance.get('/api/ga4/google-ads-analysis/', { params });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Error fetching data');
      }
    }
  );
  

// Async thunk to predict revenue and ROAS for a given cost_input
export const predictGoogleAds = createAsyncThunk(
  'googleAds/predictGoogleAds',
  async ({ costInput, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.post(
        '/api/ga4/google-ads-analysis/',
        { cost_input: costInput },
        {
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error predicting data');
    }
  }
);

const googleAdsSlice = createSlice({
  name: 'googleAds',
  initialState: {
    data: null,        // Holds the response from the API
    status: 'idle',    // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoogleAdsData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGoogleAdsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchGoogleAdsData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch data';
      })
      .addCase(predictGoogleAds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(predictGoogleAds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload; // Updated data with predictions
        state.error = null;
      })
      .addCase(predictGoogleAds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to predict data';
      });
  },
});

export default googleAdsSlice.reducer;
