// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import { store } from './store/store'; // Named import of the Redux store

import LoginPage from './pages/generic/LoginPage';
import LogoutPage from './pages/generic/LogoutPage';
import SignupPage from './pages/generic/SignupPage';
import ForgotPasswordPage from './pages/generic/ForgotPasswordPage';
import SelectAppPage from './pages/generic/SelectAppPage';
import SelectAccountPropertyPage from './pages/ga4/SelectAccountPropertyPage';
import ConnectGA4AccountPage from './pages/ga4/ConnectGA4AccountPage';
import AccountPropertyConnectPage from './pages/ga4/AccountPropertyConnectPage';
import DashboardPage from './pages/ga4/DashboardPage';
import OverviewPage from './pages/ga4/OverviewPage';
import AudiencePage from './pages/ga4/AudiencePage';
import DemographicsPage from './pages/ga4/DemographicsPage'; // Import DemographicsPage
import InterestsPage from './pages/ga4/InterestsPage';
import GeoPage from './pages/ga4/GeoPage';
import DevicePage from './pages/ga4/DevicePage';
import AcquisitionPage from './pages/ga4/AcquisitionPage';
import BehaviourPage from './pages/ga4/BehaviourPage';
import EcommercePage from './pages/ga4/EcommercePage';
import CategoryInterestPage from './pages/ga4/CategoryInterestPage';
import CampaignPage from './pages/ga4/CampaignPage';
import ConversionsPage from './pages/ga4/ConversionsPage';
import GoogleAdsPage from './pages/ga4/GoogleadsPage';
import GoogleAdsAccountsPage from './pages/google_ads/GoogleAdsAccountsPage';
import ConnectGoogleAdsAccountPage from './pages/google_ads/ConnectGoogleAdsAccountPage';
import SelectGoogleAdsAccountPage from './pages/google_ads/SelectGoogleAdsAccountPage';

// You can uncomment the following line if you use private routing
// import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    // Wrap the app with the Provider and pass the store to it
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />

          {/* Protected Routes */}
          {/* Uncomment if you have private routes implemented */}
          {/* <Route path="/dashboard" element={<PrivateRoute component={DashboardPage} />} /> */}
          <Route path="/selectapp" element={<SelectAppPage />} />
          <Route path="/select-account-property" element={<SelectAccountPropertyPage />} />
          <Route path="/connect-ga4-account" element={<ConnectGA4AccountPage />} />
          <Route path="/connect-properties" element={<AccountPropertyConnectPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/overview" element={<OverviewPage />} />
          <Route path="/audience" element={<AudiencePage />} />    
          <Route path="/demographics" element={<DemographicsPage />} /> {/* Added DemographicsPage */}      
          <Route path="/interests" element={<InterestsPage />} />
          <Route path="/geo" element={<GeoPage />} />
          <Route path="/device" element={<DevicePage />} />
          <Route path="/acquisition" element={<AcquisitionPage />} />
          <Route path="/behaviour" element={<BehaviourPage />} />
          <Route path="/ecommerce" element={<EcommercePage />} />
          <Route path="/categoryinterest" element={<CategoryInterestPage />} />
          <Route path="/campaign" element={<CampaignPage />} />
          <Route path="/conversions" element={<ConversionsPage />} />
          <Route path="/googleads" element={<GoogleAdsPage />} />
          <Route path="/connect-google-ads" element={<GoogleAdsAccountsPage />} />
          <Route path="/connect-google-ads-account" element={<ConnectGoogleAdsAccountPage />} />
          <Route path="/select-google-ads-account" element={<SelectGoogleAdsAccountPage />} />
          

        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
