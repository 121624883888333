import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountPropertyPairs,
  selectAccountProperty,
} from "./../features/ga4/accountPropertySlice";
import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import axiosInstance from "../services/axiosInstance";

const SelectAccountProperty = ({ onSelectionChange }) => {
  const dispatch = useDispatch();
  const {
    accountPropertyPairs,
    status,
    error,
    selectedAccount,
    selectedProperties,
  } = useSelector((state) => state.accountProperty);

  const [selectedAccountProperty, setSelectedAccountProperty] = useState(
    localStorage.getItem("selectedAccountProperty") || ""
  );

  // Handle token-related errors and redirect to authorization if necessary
  useEffect(() => {
    if (
      error &&
      error
        .toLowerCase()
        .includes("authentication credentials were not provided")
    ) {
      const backendUrl =
        process.env.REACT_APP_BACKEND_URL || "https://localhost:8000";
      window.location.href = `${backendUrl}/authorize/`;
    }
  }, [error]);

  // Fetch account-property pairs if they are not loaded already
  useEffect(() => {
    if (status !== "loading" && !accountPropertyPairs.length) {
      dispatch(fetchAccountPropertyPairs()).catch((err) => {
        console.error("Failed to fetch account-property pairs:", err);
      });
    }
  }, [dispatch, accountPropertyPairs.length, status]);

  // Update selection in localStorage and notify parent when selection changes
  useEffect(() => {
    if (selectedAccount && selectedProperties.length) {
      const selectedValue = JSON.stringify({
        accountId: selectedAccount,
        propertyId: selectedProperties[0],
      });
      setSelectedAccountProperty(selectedValue);
      localStorage.setItem("selectedAccountProperty", selectedValue);

      if (onSelectionChange) {
        onSelectionChange(selectedAccount, selectedProperties[0]);
      }
    }
  }, [selectedAccount, selectedProperties, onSelectionChange]);

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedAccountProperty(selectedValue);
    localStorage.setItem("selectedAccountProperty", selectedValue);

    const { accountId, propertyId } = JSON.parse(selectedValue);
    dispatch(
      selectAccountProperty({
        selectedAccount: accountId,
        selectedProperties: [propertyId],
      })
    );

    if (onSelectionChange) {
      onSelectionChange(accountId, propertyId);
    }
  };

  // Render loading state
  if (status === "loading") {
    return (
      <div className="loading-container">
        <CircularProgress />
        <p>Loading account and property data...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <Alert severity="error">
        {typeof error === "string" ? error : "An unknown error occurred."}
      </Alert>
    );
  }

  // Render prompt if no account-property pairs are available
  if (!accountPropertyPairs.length) {
    return <p>Please connect a GA4 property to continue.</p>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="select-account-property-label">
        Select Account / Property
      </InputLabel>
      <Select
        sx={{
          ".MuiSelect-select": {
            padding: "10px 16px",
          },
        }}
        labelId="select-account-property-label"
        value={selectedAccountProperty}
        onChange={handleSelectionChange}
        label="Select Account / Property"
        required>
        {accountPropertyPairs.map((pair) => (
          <MenuItem
            key={`${pair.account_id}-${pair.property_id}`}
            value={JSON.stringify({
              accountId: pair.account_id,
              propertyId: pair.property_id,
            })}>
            {pair.account_name} / {pair.property_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectAccountProperty;
