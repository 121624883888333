import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography, List, ListItem, Collapse, Divider } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import axios from "axios";

function Settings() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tokenUsage, setTokenUsage] = useState({
    usedPercentage: 0,
    remaining: 0,
    total: 0,
  });

  const handleToggle = () => setIsOpen((prev) => !prev);
  const handleDropdownToggle = () => setDropdownOpen((prev) => !prev);

  // Fetch token count data from API on component mount
  useEffect(() => {
    const fetchTokenCount = async () => {
      try {
        const response = await axios.get("/api/get_token_count/");
        const { token_allowance, fixed_tokens_remaining, tokens_purchased } = response.data;
        const totalTokens = token_allowance + tokens_purchased;
        const usedPercentage = 100 - Math.round((fixed_tokens_remaining * 100) / totalTokens);

        setTokenUsage({
          usedPercentage,
          remaining: fixed_tokens_remaining,
          total: totalTokens,
        });
      } catch (error) {
        console.error("Error fetching token data:", error);
      }
    };

    fetchTokenCount();
  }, []);

  return (
    <ListItem
      button
      onClick={handleToggle}
      sx={{
        width: "auto",
      }}>
      <Box display="flex" alignItems="center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.295 9.015a3 3 0 1 0-.59 5.97 3 3 0 0 0 .59-5.97v0zM19.518 12a7.238 7.238 0 0 1-.072.975l2.12 1.662a.506.506 0 0 1 .115.644l-2.005 3.469a.507.507 0 0 1-.616.215l-2.105-.847a.753.753 0 0 0-.711.082 7.703 7.703 0 0 1-1.01.588.748.748 0 0 0-.413.569l-.316 2.244a.52.52 0 0 1-.5.43h-4.01a.52.52 0 0 1-.501-.415l-.315-2.242a.753.753 0 0 0-.422-.573 7.279 7.279 0 0 1-1.006-.59.75.75 0 0 0-.708-.08l-2.104.848a.507.507 0 0 1-.616-.215l-2.005-3.469a.506.506 0 0 1 .115-.644l1.79-1.406a.752.752 0 0 0 .282-.66 6.392 6.392 0 0 1 0-1.165.75.75 0 0 0-.284-.654L2.43 9.36a.507.507 0 0 1-.112-.641L4.324 5.25a.507.507 0 0 1 .616-.215l2.105.847a.755.755 0 0 0 .71-.082 7.71 7.71 0 0 1 1.01-.587.747.747 0 0 0 .414-.57L9.494 2.4a.52.52 0 0 1 .501-.43h4.01a.52.52 0 0 1 .5.416l.316 2.241a.753.753 0 0 0 .422.573c.35.17.687.366 1.006.59a.75.75 0 0 0 .708.08l2.104-.848a.507.507 0 0 1 .616.215l2.005 3.469a.507.507 0 0 1-.115.644l-1.791 1.406a.752.752 0 0 0-.284.66c.016.195.026.39.026.585z"
            stroke="#007EFC"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography sx={{ ml: 1 }}>Settings</Typography>
      </Box>
      {isOpen ? <ExpandLess /> : <ExpandMore />}

      {/* Settings Submenu */}
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{
          position: "absolute",
          top: "50px", // Adjust as needed
          right: "0",
          width: "220px",
          backgroundColor: "#f0f0f0",
          padding: "16px",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          zIndex: 10,
        }}>
        <List component="div" disablePadding>
          <ListItem>
            {/* Token Usage */}
            <Box sx={{ width: "100%" }}>
              <Typography variant="body2">Tokens</Typography>
              <Box sx={{ width: "100%", height: 8, backgroundColor: "#ddd", borderRadius: 4, overflow: "hidden", mt: 1 }}>
                <Box sx={{ width: `${tokenUsage.usedPercentage}%`, height: "100%", backgroundColor: "#007EFC" }}></Box>
              </Box>
              <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
                {tokenUsage.remaining} / {tokenUsage.total} tokens remaining
              </Typography>
            </Box>
          </ListItem>

          <Divider />

          {/* Additional Links */}
          <ListItem>
            <Link to="/account_property_connect">
              <i className="fas fa-plug" /> Connect GA4
            </Link>
          </ListItem>

          {/* Settings Dropdown */}
          <ListItem button onClick={handleDropdownToggle}>
            <Box display="flex" alignItems="center">
              <i className="fas fa-cog" />
              <Typography sx={{ ml: 1 }}>Settings</Typography>
            </Box>
            {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 2 }}>
              <ListItem>
                <Link to="/profile">User Profile</Link>
              </ListItem>
              <ListItem>
                <Link to="/subscriptions">Subscription</Link>
              </ListItem>
              <ListItem>
                <Link to="/token-packages">Token Top up</Link>
              </ListItem>
              <ListItem>
                <Link to="/billing">Billing</Link>
              </ListItem>
              <ListItem>
                <Link to="/change_password">Change Password</Link>
              </ListItem>
            </List>
          </Collapse>

          {/* Logout Button */}
          <ListItem>
            <form method="post" action="/account_logout">
              <Button type="submit" variant="contained" color="secondary">
                Logout
              </Button>
            </form>
          </ListItem>
        </List>
      </Collapse>
    </ListItem>
  );
}

export default Settings;
