import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { checkAuthorization } from '../../features/ga4/ga4Slice'; // Redux action
import { checkGoogleAdsAuthorization } from '../../features/google_ads/googleAdsAccountsSlice'; // Redux action for Google Ads
import analystIcon from '../../assets/img/icon-analyst-mono.svg';
import seoIcon from '../../assets/img/seo.svg';
import contentIcon from '../../assets/img/icon-content-mono.svg';
import insightsIcon from '../../assets/img/icon-insights-mono.svg';
import strategyIcon from '../../assets/img/icon-strategy-mono.svg';
import logo from '../../assets/img/sowhatlabs_logo.svg';

const SelectAppPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, hasGa4Property } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    hasGa4Property: state.ga4.hasGa4Property, // e.g. from your ga4Slice
  }));

  const handleWebAnalystClick = async () => {
    try {
      // Dispatch Redux action to check GA4 authorization
      const result = await dispatch(checkAuthorization());

      if (checkAuthorization.fulfilled.match(result)) {
        // If authorized, navigate to account selection page
        navigate('/select-account-property');
      } else if (result.payload.redirect === 'connect_ga4_account') {
        // If no GA4 credentials, navigate to connect Google account page
        navigate('/connect-ga4-account');
      } else {
        console.error('Unexpected response data:', result.payload);
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      /**
       * 1) If the interceptor already handles 403 + redirect, we can skip an alert
       *    by detecting 403 with a redirect field.
       */
      if (error?.response?.status === 403 && error.response?.data?.redirect) {
        // The interceptor or code is forcibly redirecting the user,
        // so skip showing a pop-up. Just return silently.
        return;
      }

      /**
       * 2) For everything else, show the alert as a fallback.
       */
      console.error('Error during authorization request:', error);
      if (error.response && error.response.status === 401) {
        alert('Session expired. Please log in again.');
      } else {
        alert('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleGoogleAdsClick = async () => {
    try {
      const result = await dispatch(checkGoogleAdsAuthorization());

      if (checkGoogleAdsAuthorization.fulfilled.match(result)) {
        // If authorized, navigate to account selection
        navigate('/select-google-ads-account');
      } else if (result.payload?.redirect === 'connect_google_ads_account') {
        // If no credentials, navigate to connect Google Ads page
        navigate('/connect-google-ads-account');
      } else {
        console.error('Unexpected response for Google Ads authorization:', result.payload);
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      // Same “suppress” logic for 403 + redirect
      if (error?.response?.status === 403 && error.response?.data?.redirect) {
        return;
      }
      console.error('Error during Google Ads authorization request:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="user-screen">
      <div className="nav">
        <div className="logo">
          <a href="/select_source/">
            <img src={logo} alt="So What Labs Logo" />
          </a>
        </div>
      </div>
      <div className="content-users big wrap1170">
        <h2>Select the app</h2>
        <div className="select-source-group select-source-3">
          {/* Web Analyst (GA4) */}
          <div className="source__info">
            <div className="source__image">
              <img src={analystIcon} alt="Web Analyst" />
            </div>
            <div className="source__name">
              <h4>Web Analyst</h4>
              <p>
                Ask a question, get insights and recommendations on Google
                Analytics data.
              </p>
            </div>
            <div className="btns">
              <button onClick={handleWebAnalystClick} className="btn btn--block">
                Web Analyst
              </button>
            </div>
          </div>

          {/* Google Ads Optimizer */}
          <div className="source__info">
            <div className="source__image">
              <img src={analystIcon} alt="Web Analyst" />
            </div>
            <div className="source__name">
              <h4>Google Ads Optimizer</h4>
              <p>
                Get recommendations on your Google Ads campaigns to improve
                efficiency.
              </p>
            </div>
            <div className="btns">
              <button onClick={handleGoogleAdsClick} className="btn btn--block">
                Google Ads Optimizer
              </button>
            </div>
          </div>

          {/* SEO Specialist */}
          <div className="source__info">
            <div className="source__image">
              <img src={seoIcon} alt="SEO Specialist" />
            </div>
            <div className="source__name">
              <h4>SEO Specialist</h4>
              <p>Get site audits, recommendations, traffic analysis, etc.</p>
            </div>
            <div className="btns">
              {hasGa4Property ? (
                <Link to="/crawl" className="btn btn--block">
                  SEO Specialist
                </Link>
              ) : (
                <a
                  href="#"
                  className="btn btn--block disabled"
                  title="Please add a GA4 property first"
                >
                  SEO Specialist
                </a>
              )}
            </div>
          </div>

          {/* Content Creator */}
          <div className="source__info">
            <div className="source__image">
              <img src={contentIcon} alt="Content Creator" />
            </div>
            <div className="source__name">
              <h4>Content Creator</h4>
              <p>
                Provide your site URL and the app writes contextual content for you.
              </p>
            </div>
            <div className="btns">
              {hasGa4Property ? (
                <Link to="/ga4_content" className="btn btn--block">
                  Content Creator
                </Link>
              ) : (
                <a
                  href="#"
                  className="btn btn--block disabled"
                  title="Please add a GA4 property first"
                >
                  Content Creator
                </a>
              )}
            </div>
          </div>

          {/* Insights Developer */}
          <div className="source__info">
            <div className="source__image">
              <img src={insightsIcon} alt="Insights Developer" />
            </div>
            <div className="source__name">
              <h4>Insights Developer</h4>
              <p>Based on your business context, get actionable insights in seconds.</p>
            </div>
            <div className="btns">
              {hasGa4Property ? (
                <Link to="/business_insights_page" className="btn btn--block">
                  Insights Developer
                </Link>
              ) : (
                <a
                  href="#"
                  className="btn btn--block disabled"
                  title="Please add a GA4 property first"
                >
                  Insights Developer
                </a>
              )}
            </div>
          </div>

          {/* Strategy Planner */}
          <div className="source__info">
            <div className="source__image">
              <img src={strategyIcon} alt="Strategy Planner" />
            </div>
            <div className="source__name">
              <h4>Strategy Planner</h4>
              <p>
                Get directions on positioning, media mix, engagement tactics, and more.
              </p>
            </div>
            <div className="btns">
              {hasGa4Property ? (
                <Link to="/business_recommendations_page" className="btn btn--block">
                  Strategy Planner
                </Link>
              ) : (
                <a
                  href="#"
                  className="btn btn--block disabled"
                  title="Please add a GA4 property first"
                >
                  Strategy Planner
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAppPage;
